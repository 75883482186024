<template>
  <div class="gird">
    <div class="layout" :class="'type' + active">
      <div
        class="cenBot fl-x-b"
        :class="'cenBot' + index"
        v-for="(item, index) in cenTopList"
        :key="index"
        @click="assetsPopup(item.name)"
      >
        <img :src="item.icon" alt="" />
        <span>{{ item.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["active", "cenTopList"],
  data() {
    return {};
  },
  methods: {
    assetsPopup(type) {
      switch (type) {
        case "免费电话":
          this.$router.push({ path: "tellfree" });
          break;
        case "图文打印":
          this.$router.push({ path: "printing" });
          break;
        case "社区团购":
          this.$router.push({ path: "groupShop" });
          break;
        case "充电宝":
          this.$router.push({ path: "charge" });
          break;
        case "健康检测":
          this.$emit("healthy");
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.layout {
  display: grid;
  padding: 0 20px;
  &.type5 {
    grid-auto-flow: row dense;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 10px;
    .cenBot0 {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 85px 0 50px 0;
      align-items: center;
      grid-row-start: span 2;
      background-color: #2498db;
      height: 100% !important;
      font-size: 30px;
      img {
        width: 66px;
        height: 83px;
      }
    }
    .cenBot1 {
      grid-column-start: span 2;
      background: #24dadb;
      font-size: 36px;
      padding: 0 30px 0 41px;
      img {
        width: 80px;
        height: 80px;
      }
    }
    .cenBot2 {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 25px 0 18px 0;
      background-color: #f37917;
      font-size: 24px;
      img {
        width: 66px;
        height: 55px;
      }
    }
    .cenBot3 {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 24px 0 18px 0;
      background-color: #eb5941;
      font-size: 24px;
      img {
        width: 57px;
        height: 57px;
      }
    }
    .cenBot4 {
      grid-column-start: span 3;
      background-color: #24db4b;
      font-size: 36px;
      padding: 0 137px 0 70px;
      img {
        width: 83px;
        height: 89px;
      }
    }
  }
}
.cenBot {
  height: 130px;
  border-radius: 10px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #ffffff;
}
</style>
