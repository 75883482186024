<template>
  <div class="home">
    <div class="fl-x-b header">
      <div class="headL fl-x-l">
        <div class="l-img">
          <img class="img-c imgSize" src="@/assets/img/logo@2x.png" />
        </div>
        <div class="l-dev fl-y te-l">
          <span class="sz-20 fw-500 f-color1">武昌区锦绣中北</span>
          <span class="sz-16 f-color6 fw-400">FC0271436</span>
        </div>
      </div>
      <div class="headR te-r">
        <div class="r-top fl-x-r">
          <div class="r-img1" @click="goTell">
            <img class="i-size" src="@/assets/img/客服@2x.png" alt="" />
          </div>
          <span class="sz-18 f-color1 fw-400 number">400-1165-2678</span>
          <div class="r-img2" @click="goTell">
            <img
              class="i-size"
              src="@/assets/img/电  话 (1) (1)@2x.png"
              alt=""
            />
          </div>
        </div>
        <div class="r-bottom sz-16 fw-400 f-color6 fl-x-r">
          <span>2020年11月26日</span>
          <span class="line-1"></span>
          <span>7℃-14℃</span>
        </div>
      </div>
    </div>
    <div class="banner">
      <el-carousel class="carousel" indicator-position="none">
        <el-carousel-item v-for="item in 4" :key="item" class="carousel-item">
          <img class="banner" src="@/assets/img/banner@2x.png" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <se-title :title="title"></se-title>
    <se-grid
      :active="active"
      :cenTopList="cenTopList"
      @healthy="healthy"
    ></se-grid>
    <!-- 健康检测毛玻璃弹框 -->
    <div v-show="glassShow" class="glass pos-t vw-100 vh-100 zx-10"></div>
    <div v-show="glassShow" class=" pos-t vw-100 vh-100 zx-20">
      <div class="return fl-x-r" @click="glassShow = false">
        <i class="el-icon-arrow-left f-color0 sz-30 fw-500"></i>
        <div class="sz-30 f-color0 fw-500 r-text">返回</div>
        <div class="sz-22 f-color0 fw-400 r-right">{{ seconds }}秒</div>
      </div>
      <div class="center pgt-210 fl-y-c">
        <div
          class="car w-466 h-148 mgt-16 mgb-16 fl-x-b pgl-35 pgr-22"
          :style="{ backgroundColor: item.background }"
          v-for="(item, index) in testList"
          :key="index"
        >
          <div class="fl-y-b pgt-20 pgb-12 fw-500 f-color0">
            <span class="sz-36">{{ item.name }}</span>
            <span class="sz-18 opt">{{ item.english }}</span>
          </div>
          <img class="w-110 h-110" :src="item.icon" alt="" />
        </div>
      </div>
    </div>
    <se-footer></se-footer>
  </div>
</template>

<script>
import Title from "@/components/title/index.vue";
import Grid from "@/components/grid/index.vue";
import Footer from "@/components/footer/index.vue";
export default {
  components: {
    "se-title": Title,
    "se-grid": Grid,
    "se-footer": Footer,
  },
  data() {
    return {
      title: "请选择服务",
      active: 5,
      glassShow: false,
      seconds: 0,
      timeOut: "",
      testList: [
        {
          name: "血氧检测",
          english: "Blood oxygen test",
          background: "#7FAAFF",
          icon: require("@/assets/img/血氧@2x.png"),
        },
        {
          name: "血压检测",
          english: "Blood pressure monitoring",
          background: "#24DADB",
          icon: require("@/assets/img/血压@2x.png"),
        },
        {
          name: "体脂检测",
          english: "Body fat test",
          background: "#FF7F7F",
          icon: require("@/assets/img/体脂@2x.png"),
        },
      ],
      cenTopList: [
        {
          name: "免费电话",
          icon: require("@/assets/img/电 话@2x.png"),
        },
        {
          name: "图文打印",
          icon: require("@/assets/img/打印 拷贝@2x.png"),
        },
        {
          name: "社区团购",
          icon: require("@/assets/img/社区团购@2x.png"),
        },
        {
          name: "充电宝",
          icon: require("@/assets/img/充电宝@2x.png"),
        },
        {
          name: "健康检测",
          icon: require("@/assets/img/检测@2x.png"),
        },
      ],
    };
  },
  methods: {
    _setInter() {
      this.timeOut = setInterval(() => {
        if (this.seconds > 0) {
          this.seconds--;
        } else {
          this.glassShow = false;
          clearInterval(this.timeOut);
        }
      }, 1000);
    },
    // 去拨号
    goTell() {
      this.$router.push({ path: "/tellfree" });
    },
    //去健康检测
    healthy() {
      this.glassShow = true;
      this.seconds = 30;
      this._setInter();
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100%;
}
.header {
  width: 100%;
  height: 78px;
}
.headL {
  padding: 12px 0 15px 20px;
  .l-img {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    background-color: #233b3c;
    position: relative;
  }
  .l-dev {
    margin-left: 18px;
  }
}
.headR {
  padding: 10px 18px 15px 0;
}
.r-top {
  .r-img1 {
    width: 27px;
    height: 24px;
    border-radius: 50;
  }
  .number {
    margin: 0 14px 0 10px;
  }
  .r-img2 {
    width: 32px;
    height: 32px;
  }
}
.imgSize {
  width: 40px;
  height: 30px;
}

.banner {
  width: 100%;
  height: 280px;
  .carousel {
    height: 280px;
  }
}
.glass {
  background: url("../../assets/img/毛玻璃.png") no-repeat;
  background-size: 100% 100%;
  filter: blur(10px);
}
.return {
  width: 170px;
  height: 60px;
  background: #24dadb;
  border-radius: 0px 30px 30px 0px;
  position: relative;
  top: 20px;
  left: 0;
  line-height: 60px;
  .r-text {
    margin: 0 8px;
  }
  .r-right {
    margin-right: 4px;
  }
}
.car {
  border-radius: 10px;
}
.opt {
  opacity: 0.6;
}
</style>
