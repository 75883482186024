<template>
  <div class="title fl-x-c">
    <span class="line1"></span>
    <span class="text">{{ title }}</span>
    <span class="line2"></span>
  </div>
</template>

<script>
export default {
  props: ["title"],
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.title {
  padding: 38px 0 18px 0;
  .line1 {
    width: 88px;
    height: 1px;
    background: linear-gradient(-90deg, #233b3c 0%, #f4f4f4 100%);
  }
  .text {
    font-size: 28px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #233b3c;
    margin: 0 20px;
  }
  .line2 {
    width: 88px;
    height: 1px;
    background: linear-gradient(90deg, #233b3c 0%, #f4f4f4 100%);
  }
}
</style>
